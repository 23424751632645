import React from 'react'
import { useEventRecipients } from '../../../../../hooks/useRecipients'
import { Box, Image, Text, VStack, useColorModeValue, useColorMode } from '@chakra-ui/react'
import { useEventOccasion } from '../../../../../hooks/useEventOccasion'
import useNumberSuffix from '../../../../../hooks/useNumberSuffix'
import PropTypes from 'prop-types'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { showYearsFieldFor } from '../../../EventDetails/schema'
// import { useLocation, useRoutes } from 'react-router-dom'

const LOGO_COLOR = '/assets/images/vidday_logo.svg'
const LOGO_GRAYSCALE = '/assets/images/vidday_logo_white.svg'
const CONFETTIS = '/assets/images/graphics/confettis.svg'

const PublicHeader = ({ hideTitle, withNewHeader, ...rest }) => {
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const { colorMode } = useColorMode()
	const color = useColorModeValue('dark', 'white')
	const eventOccasion = useEventOccasion()
	const yearSuffix = useNumberSuffix(event.years)
	const isMemorial = eventOccasion.label == 'Memorial'
	return (
		<Box w="full" textAlign="center" {...rest} px={['1rem', null, 'inherit']}>
			<VStack spacing="0.5rem">
				{!withNewHeader && !isMemorial && (
					<Image mx="auto" src={CONFETTIS} w="343px" h="49px" alt="confettis" />
				)}

				<Image
					mx="auto"
					src={colorMode == 'dark' ? LOGO_GRAYSCALE : LOGO_COLOR}
					w="109px"
					h="32px"
					mt={isMemorial || withNewHeader ? '1rem' : 0}
					alt="VidDay's Logo"
				/>

				{!withNewHeader && !hideTitle && (
					<Text color={color} fontSize="1.0625rem">
						{event.years && showYearsFieldFor.includes(event.occasion)
							? event.years + yearSuffix + ' '
							: ''}
						{eventOccasion.label} Video for <strong>{recipients.formatted}</strong>
					</Text>
				)}
			</VStack>
		</Box>
	)
}

PublicHeader.defaultProps = {
	hideTitle: false,
}

PublicHeader.propTypes = {
	hideTitle: PropTypes.bool,
}

export default PublicHeader
