import React from 'react'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../../../api/app/events'
import { useNavigate } from 'react-router-dom'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import { useAuthContext } from '../../../contexts/AuthProvider'

const ModalActivateEventPage = ({ onClose, isOpen, event }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { hasSubscription } = useAuthContext()

	const handleUnArchiveEvent = () => {
		var eventData = { id: event?.id, status: 'active', paid: false, contributorsNotified: false }
		dispatch(updateEvent(eventData))
		navigate(`/event/${event.uuid}`)
	}

	let title, message, cancelLabel, acceptLabel
	switch (event.status) {
		case 'publishing':
			title = 'You are canceling production'
			message = (
				<>
					<Text>By doing so, you will lose the current version of the video.</Text>
				</>
			)
			acceptLabel = 'Cancel Production'
			break
		default:
			title = "You're reopening your event page"
			message = (
				<>
					<Text>
						You can always reopen your Event Page to edit or add more media, but we will need to remake your
						video.{' '}
						{!hasSubscription &&
							'The first remake is free of charge, and the following remakes are subject to a 30% fee.'}
					</Text>
					<Text>We suggest downloading the current video to your computer before continuing.</Text>
				</>
			)
			cancelLabel = 'Go Back'
			acceptLabel = 'Reopen Event Page'
	}

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/icons/icon_warning.svg'} alt="Preview Started" />
						<Heading size="lg" variant="hero">
							{title}
						</Heading>

						{message}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						{cancelLabel && (
							<Button variant="ghost" onClick={onClose}>
								{cancelLabel}
							</Button>
						)}
						<Button onClick={handleUnArchiveEvent}>{acceptLabel}</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalActivateEventPage
