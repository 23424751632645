import { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

const useRenderJobs = () => {
	const renderJobs = useSelector((state) => state.event.renderJobs, shallowEqual)

	return renderJobs
}

export default useRenderJobs
