import { useEffect, useMemo, useState } from 'react'
import { eventTypes } from '@vidday/data'
import { useEventContext } from '../contexts/EventProvider'
import { shallowEqual, useSelector } from 'react-redux'
import useTemporaryEvent from './useTemporaryEvent'

const determineOccasion = (ev) => {
	let eventType = eventTypes.find((i) => ev.occasion === i.type)
	return ev.occasion == 'other' || ev.occasion == 'congratulations'
		? { ...eventType, label: ev.occasionOther }
		: eventType
}

// export const useEventOccasionV2 = () => {
// 	const event = useSelector((s) => s.event.event, shallowEqual)
// 	const temporaryEvent = useSelector((s) => s.temporaryEvent.data, shallowEqual)

// 	const [occasion, setOccasion] = useState(determineOccasion(temporaryEvent.length > 0 ? temporaryEvent : event))

// 	useEffect(() => {
// 		if (event || temporaryEvent) {
// 			setOccasion(determineOccasion(temporaryEvent.length > 0 ? temporaryEvent : event))
// 		}
// 	}, [event, temporaryEvent])

// 	return occasion
// }

export const useEventOccasion = (event) => {
	/** Fallback to event from context */
	const eventState = useEventContext()
	const temporaryEvent = useTemporaryEvent()

	const ev = event ? event : temporaryEvent ? temporaryEvent : eventState.event

	/** Get occasion's object */
	const occasion = useMemo(() => {
		if (ev) {
			return ev.occasion == 'other' ? { label: ev.occasionOther } : eventTypes.find((i) => ev.occasion === i.type)
		}
	}, [ev.occasion])

	return occasion
}
