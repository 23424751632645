import { useEffect, useState } from 'react'

export const useNumberSuffix = (number) => {
	const [suffix, setSuffix] = useState('')

	useEffect(() => {
		const getNumberSuffix = () => {
			// not a number return empty string
			if (isNaN(number)) {
				setSuffix('')
				return
			}

			// Special case for 11-13
			if (number % 100 >= 11 && number % 100 <= 13) {
				setSuffix('th')
			} else {
				switch (number % 10) {
					case 1:
						setSuffix('st')
						break
					case 2:
						setSuffix('nd')
						break
					case 3:
						setSuffix('rd')
						break
					default:
						setSuffix('th')
						break
				}
			}
		}

		getNumberSuffix()
	}, [number])

	return suffix
}

export default useNumberSuffix
