import * as Yup from 'yup'

export const showYearsFieldFor = ['birthday', 'anniversary']
export const showOtherFieldFor = ['other', 'congratulations', 'thankyou']

const schemaEventDetail = Yup.object().shape({
	occasion: Yup.string().required('Please select an occasion.'),
	occasionOther: Yup.string().when('occasion', {
		is: (occasion) => occasion !== '' && (occasion === 'other' || occasion === 'congratulations'),
		then: () => Yup.string().max(50, 'Maximum of 50 characters.').required('Please enter the occasion title.'),
		otherwise: () => Yup.string().notRequired().default(''),
	}),
	years: Yup.number().when('occasion', {
		is: (occasion) => {
			return showYearsFieldFor.includes(occasion)
		},
		then: () =>
			Yup.number()
				.positive()
				.integer()
				.nullable()
				.default(null)
				.transform((v, o) => (o === '' || o === null || o === undefined ? null : v)),
		otherwise: () =>
			Yup.number()
				.nullable()
				.default(null)
				.transform((v, o) => (o === '' || o === null || o === undefined ? null : v)),
	}),
	recipients: Yup.array()
		.min(1)
		.of(
			Yup.object().shape({
				firstName: Yup.string().max(30).required('You need to provide a first name.'),
				lastName: Yup.string()
					.max(35)
					.nullable()
					.default(null)
					.transform((v, o) => (o === '' || o === null || o === undefined ? null : v)),
				id: Yup.string().nullable().default(null),
			})
		)
		.required(),
})

export default schemaEventDetail
